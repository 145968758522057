import React, { useEffect } from 'react';
import './Navbar.css';
import Titre from '../../elements/Titre/Titre';
import diskImage from '../../Assets/disk.png';
import anime from 'animejs';

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isInfoVisible: false
    };
    this.diskImageRef = React.createRef();
  }

  componentDidMount() {
    // Trigger the animation when the component mounts
    this.animateAppearance();
  }

  animateAppearance() {
    anime({
      targets: '.img',
      translateY: [-100, 0],
      duration: 1500,
    });
  }
  

  render() {
    return (
      <nav className='navbar-nav'>
        <div className="navbar-container">
          <div className="navbar-top">
            <Titre />
          </div>
          <div className='img'>
          <img
            ref={this.diskImageRef}
            src={diskImage}
            height='150'
            alt="music disk"
          /></div>
          <div className="navbar-bottom"></div>
        </div>
      </nav>
    );
  }
}

export default Navbar;
