import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import SearchBar from './elements/Searchbar/Searchbar';
import Grid from './components/Grid/Grid';
import './App.css'; // Adjust the path if needed

function App() {
  return (
    <BrowserRouter>
      <div>
        <Navbar />
        <div className="search_grid">

        <SearchBar />
        <Grid/>
        </div>
      </div>
    </BrowserRouter>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));
