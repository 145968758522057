import React, { useState, useEffect, useRef } from 'react';
import './Grid.css';
import anime from 'animejs'; // Import Anime.js
import html2canvas from 'html2canvas';



function Grid() {
  const [width, setWidth] = useState(3); // Initial width value
  const [height, setHeight] = useState(3); // Initial height value
  const [animationFinished, setAnimationFinished] = useState(false);


  useEffect(() => {
    // Update the custom CSS variables --width and --height
    document.documentElement.style.setProperty('--width', width);
    document.documentElement.style.setProperty('--height', height);
  }, [width, height]);

  useEffect(() => {
    const animation = anime({
      targets: '.grid',
      translateX: [500, 0],
      duration: 1500,
      complete: () => {
        setAnimationFinished(true);
      },
    });

    return () => {
      animation.pause();
    };
  }, []);

  useEffect(() => {
    const animation = anime({
      targets: '.cell',
      translateX: [1500, 0], // Move the search bar up and down
      duration: 100, // Animation duration in milliseconds
      delay: anime.stagger(10, {start: 1}), // delay starts at 500ms then increase by 100ms for each elements.
      complete: () => {
        setAnimationFinished(true);
      },

    });
  }, []);

  useEffect(() => {
    if (animationFinished) {
      const cells = document.querySelectorAll('.cell');

      cells.forEach((cell) => {
        cell.style = ''; // Supprimer le style des cellules
      });
    }
  }, [animationFinished]);

  const handleWidthChange = (event) => {
    setWidth(Number(event.target.value));
  };

  const handleHeightChange = (event) => {
    setHeight(Number(event.target.value));
  };

  const handleDragStart = (event) => {
    event.dataTransfer.setData('text/plain', event.target.id);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    event.target.classList.add('hovered'); // Ajoutez la classe pour l'effet de survol
  };
  
  const handleDragLeave = (event) => {
    event.target.classList.remove('hovered'); // Retirez la classe lorsqu'il quitte la zone de survol
  };

  const handleDragLeaveSlot = (event) => {
    event.target.classList.remove('hovered');
  };


  const gridRef = useRef(null);

  const handleDownload = () => {
    // Capture the grid with its images using html2canvas
    html2canvas(gridRef.current, { useCORS: true }).then((canvas) => {
      // Convert the canvas screenshot to a data URL
      const dataURL = canvas.toDataURL();

      // Create a link for downloading the image
      const downloadLink = document.createElement('a');
      downloadLink.setAttribute('download', 'grid.png');
      downloadLink.setAttribute('href', dataURL);
      document.body.appendChild(downloadLink);
      downloadLink.click();

      // Remove the link from the document
      document.body.removeChild(downloadLink);
    });};
  
  
  
  

  // ...

const handleClear = () => {
  // Sélectionnez toutes les cellules avec la classe '.cell'
  const cells = document.querySelectorAll('.cell');

  // Parcourez chaque cellule et supprimez son contenu
  cells.forEach((cell) => {
    cell.innerHTML = '';
  });
};

// ...


  const handleDrop = (event) => {
    const slot = event.target;
    event.target.classList.remove('hovered'); // Retirez la classe lorsque l'élément est déposé

    event.preventDefault();
  
    const data = event.dataTransfer.getData('text/plain');
    const draggedImage = document.getElementById(data);
  
    const isListItem = draggedImage.classList.contains('liste');
    const isGridItem = draggedImage.classList.contains('grid');

    const targetSlot = event.target.parentElement;
    console.log("target",targetSlot);
    const draggedSlot = draggedImage.parentElement;
    console.log(draggedSlot);
  
    if (isListItem) {

      const clonedImage = draggedImage.cloneNode(true);
      const slotWidth = slot.offsetWidth;
      const slotHeight = slot.offsetHeight;
      clonedImage.style.width = `${slotWidth}px`;
      clonedImage.style.height = `${slotHeight}px`;

      // Génération d'un identifiant unique basé sur le timestamp
      const uniqueId = `grid_${Date.now()}`;
  
      event.target.appendChild(clonedImage);
  
      clonedImage.classList.remove('liste');
      clonedImage.removeAttribute('id');
      clonedImage.setAttribute('id', uniqueId);
  
      clonedImage.classList.add('grid');

      if (event.target.tagName.toLowerCase() === 'img') {
        console.log("Success: Image dropped on another image");

        const targetSrc = event.target.src;

        const draggedSrc = draggedImage.src;

        event.target.src = draggedSrc;
        
    }

    } 
    else if (isGridItem) {

      if (event.target.src===draggedImage.src) {
        return; // Ne rien faire dans ces cas
    }
      const clonedImage = draggedImage.cloneNode(true);
      const slotWidth = slot.offsetWidth;
      const slotHeight = slot.offsetHeight;
      clonedImage.style.width = `${slotWidth}px`;
      clonedImage.style.height = `${slotHeight}px`;
  
      // Génération d'un identifiant unique basé sur le timestamp
      const uniqueId = `grid_${Date.now()}`;
  
      // Append the cloned image to the new slot
      event.target.appendChild(clonedImage);
  
      // Remove the original element from its old slot
      const oldSlot = draggedImage.parentElement;
      oldSlot.removeChild(draggedImage);
  
      // Attribuer l'identifiant unique au nouvel élément
      clonedImage.removeAttribute('id');
      clonedImage.setAttribute('id', uniqueId);
  
      // Vérifier si l'événement cible (event.target) contient une image
      if (event.target.tagName.toLowerCase() === 'img') {
        // Obtenir les numéros de slot des images
       


        // Vérifier si les images sont dans des slots numérotés
        if (targetSlot !== null && draggedSlot !== null) {
          if (targetSlot !== draggedSlot) {
        
            const targetSrc = event.target.src;
            const draggedSrc = draggedImage.src;
        
            event.target.src = draggedSrc;
            draggedImage.src = targetSrc;
        
            const newImage = document.createElement('img');
            newImage.src = targetSrc;


              console.log("test");
              draggedSlot.appendChild(newImage);
              newImage.style.width = `${slotWidth}px`;
              newImage.style.height = `${slotHeight}px`;

              const uniqueId = `grid_${Date.now()}`;
  

  
              newImage.setAttribute('id', uniqueId);
              newImage.classList.add('grid');


            
        }
        
        }
    }
  }
  
};



  
  

  
  
  
  
  
  

  const renderSlots = () => {
    const slots = [];

    for (let i = 0; i < width * height; i++) {
      const slotClassName = `cell slot${i}`;

      slots.push(
        <div className={slotClassName} key={i} onDragOver={handleDragOver} onDrop={handleDrop}

          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeaveSlot}
          ></div>
      );
    }

    return slots;
  };

  
  

  return (
    <div className="grid" onDragStart={handleDragStart}>
      <div className="grid-controls">
        <label htmlFor="width-input" className="label-with-margin">
          Width: {width}
          <input
            className='resize-input'
            id="width-input"
            type="range"
            min={1}
            max={10}
            value={width}
            onChange={handleWidthChange}
          />
        </label>
  
        <label htmlFor="height-input" className="label-with-margin">
          Height: {height}
          <input
            className='resize-input'
            id="height-input"
            type="range"
            min={1}
            max={10}
            value={height}
            onChange={handleHeightChange}
          />
        </label>
      </div>
  
      <div className="grid-container" ref={gridRef}>{renderSlots()}</div>
  
      <div className="grid-buttons">
        <button onClick={handleClear}>Clear</button>
        <button onClick={handleDownload}>Download</button>
      </div>
    </div>
  );
  
  
  
}

export default Grid;
