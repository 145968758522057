import React from 'react';
import './Titre.css';

function Title() {
  return (
    <h1 className="titre">
      Make Your Own Album Chart
    </h1>
  );
}

export default Title;
