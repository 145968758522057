import React, { useState, useCallback, useEffect, useRef } from 'react';
import axios from 'axios';
import './Searchbar.css';
import Liste from '../../components/Liste/Liste';
import anime from 'animejs'; // Import Anime.js


const API_KEY = '5373e75bccce3bc16d4b3d17f1a0fa22';

function SearchBar() {

  useEffect(() => {
    anime({
      targets: '.searchbar-container',
      translateX: [-500, 0], // Move the search bar up and down
      duration: 1500, // Animation duration in milliseconds
    });
  }, []);

  const [query, setQuery] = useState('jackson');
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  const search = useCallback(async () => {
    try {
      setIsLoading(true);
      // Réinitialiser les résultats à chaque nouvelle recherche
      setResults([]);
  
      // Effectuer la recherche
      const url = `http://ws.audioscrobbler.com/2.0/?method=album.search&album=${encodeURIComponent(
        query
      )}&api_key=${API_KEY}&format=json`;
  
      const response = await axios.get(url);
      const data = response.data;
  
      if (data.results.albummatches.album) {
        setResults(data.results.albummatches.album);
      } else {
        setResults([]);
      }
  
    } catch (error) {
      console.error('Error searching albums:', error);
    } finally {
      setIsLoading(false);
    }
  }, [query]);
  
  
  

  

  const handleSearch = async (event) => {
    event.preventDefault();
    await search();
  };
  

  return (
    <div className='searchbar-container'>
      
      <form onSubmit={handleSearch}>
        <input
          className='searchbar-input'
          type='text'
          value={query}
          onChange={(event) => setQuery(event.target.value)}
          placeholder='Search albums'
        />
        <button type='submit' className='searchbar-button'>
          Search
        </button>
      </form>
      {
        results.length > 0 && <Liste results={results} /> // Affiche la liste des résultats si elle n'est pas vide
      }
    </div>
  );
  
  
  
}

export default SearchBar;
