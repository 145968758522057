import React, { useEffect, useState } from 'react';
import './Liste.css';
import anime from 'animejs'; 

function Liste({ results }) {

  

  const handleDragStart = (event) => {
    event.dataTransfer.setData('text/plain', event.target.id);
  };

  return (
    <ul className="liste-container">
      {results.map((album) => (
        <li className="liste-item" key={album.name} draggable onDragStart={handleDragStart}>
          {album.image && album.image[2] && album.image[2]['#text'] && (
            <img className="liste" src={album.image[2]['#text']} alt={album.name} id={album.name} />
          )}
        </li>
      ))}
    </ul>
  );
}

export default Liste;
